import { BACKEND_URL } from "../../routes/config";
import { liveViewTunnelUserProfile } from "../../configs/dataConfig";
class ApiSpec {
  constructor() {
    this.forgetPassword = `${BACKEND_URL}/password-reset/`;
    this.login = `${BACKEND_URL}/login/`;
    this.getuserprofile = `${BACKEND_URL}/userprofile/`;
    this.logout = `${BACKEND_URL}/token`;
    this.refreshToken = `${BACKEND_URL}/token/refresh/`;
    this.profile = `${BACKEND_URL}/profile/`;
    this.getuserprofileById = (id) => {
      return `${BACKEND_URL}/updateprofile/${id}/`;
    };
    // forgte pasword
    this.forgetPassSubmit = (uid, token) => {
      return `${BACKEND_URL}/password-reset/confirm/${uid}/${token}/`;
    };
    this.getProfileSetting = (id, profile_type) => {
      return `${BACKEND_URL}/userprofile/${id}/${profile_type}/`;
    };
    this.updateProfileSetting = (id) => {
      return `${BACKEND_URL}/updateprofile/${id}/`;
    };
    // common endpoints

    this.getStoresList = (account_id) => {
      return `${BACKEND_URL}/facility/${account_id}/`;
    };
    // get client list
    this.getClientList = (account_id) => {
      return `${BACKEND_URL}/client/${account_id}/`;
    };

    // get profile id by client
    this.getProfileIdByClient = (account_id) => {
      return `${BACKEND_URL}/facility/client/${account_id}/`;
    };

    this.getDevicesList = (facility_id, user_profile_id) => {
      return `${BACKEND_URL}/facility/device/${facility_id}/${user_profile_id}/`;
    };
    // get cameras by store
    this.getCamerasByStore = (facility_id) => {
      let user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/facility/camera/${facility_id}/${user_profile_id}/`;
    };
    // Remote Healt Monitoring endpoints

    this.getNvrByStore = (facility_id) => {
      let user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/nvr/facility/${facility_id}/${user_profile_id}/`;
    };

    this.nvrStatusByAccount = (account_id, page) => {
      return `${BACKEND_URL}/nvr-status/userprofile/${account_id}/${page}/`;
    };

    this.nvrStatusByStore = (store_id, page) => {
      let user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/nvr-status/facility/${store_id}/${page}/${user_profile_id}`;
    };

    this.nvrStatusByNVR = (nvr_id) => {
      return `${BACKEND_URL}/nvr-status/nvr/${nvr_id}/`;
    };
    //                             search for nvr endpoints

    this.nvrStatusSearchByAccount = (account_id, page, search_item) => {
      return `${BACKEND_URL}/nvr-status/filter/userprofile/${account_id}/${search_item}/${page}/`;
    };
    this.nvrStatusSearchByStore = (store_id, page, search_item) => {
      return `${BACKEND_URL}/nvr-status/filter/facility/${store_id}/${search_item}/${page}/`;
    };

    //                             Online for nvr endpoints

    this.nvrStatusByAccountByType = (account_id, page, type) => {
      return `${BACKEND_URL}/nvr-status/${type}/userprofile/${account_id}/${page}/`;
    };
    this.nvrStatusByStoreByType = (store_id, page, type) => {
      let user_profile_id = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/nvr-status/${type}/facility/${store_id}/${page}/${user_profile_id}`;
    };
    //                             offline for nvr endpoints

    // this.nvrStatusOfflineByAccount = (account_id, page) => {
    //   return `${BACKEND_URL}/nvr-status/offline/userprofile/${account_id}/${page}/`;
    // };
    // this.nvrStatusOfflineByStore = (store_id, page) => {
    //   return `${BACKEND_URL}/nvr-status/offline/facility/${store_id}/${page}/`;
    // };
    // //                             Abnormal for nvr endpoints

    // this.nvrStatusAbnormalByAccount = (account_id, page) => {
    //   return `${BACKEND_URL}/nvr-status/hddabnormal/userprofile/${account_id}/${page}/`;
    // };
    // this.nvrStatusAbnormalByStore = (store_id, page) => {
    //   return `${BACKEND_URL}/nvr-status/hddabnormal/facility/${store_id}/${page}/`;
    // };

    this.totalNvrDataForLocalDownload = (account_id) => {
      return `${BACKEND_URL}/nvr-status/reportdownload/${account_id}/`;
    };

    // Retaile Dashbored endpoints
    //                                          get hourly peopel count endpoinst
    this.getPeopleCountHourlyByAccount = (
      account_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/peoplecount/userprofile/${account_id}/people-count-hourly/${date}/${startTime}/${endTime}/`;
    };

    this.getPeopleCountHourlyByStore = (
      facility_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/peoplecount/facility/${facility_id}/people-count-hourly/${date}/${startTime}/${endTime}/`;
    };

    this.getPeopleCountHourlyByCamera = (
      device_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/peoplecount/device/${device_id}/people-count-hourly/${date}/${startTime}/${endTime}/`;
    };

    // Group Count api endpoints

    this.GroupCountByAccount = (account_id, date) => {
      return `${BACKEND_URL}/group_people/userprofile/${account_id}/${date}/`;
    };
    this.GroupCountByStore = (store_id, date) => {
      return `${BACKEND_URL}/group_people/facility/${store_id}/${date}/`;
    };
    this.GroupCountByCamera = (cam_id, date) => {
      return `${BACKEND_URL}/group_people/device/${cam_id}/${date}/`;
    };

    //get hourly Passer count endpoints
    this.getPasserCountCountHourlyByAccount = (
      account_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/passer/userprofile/${account_id}/passerby-hourly/${date}/${startTime}/${endTime}/`;
    };

    this.getPasserCounttHourlyByStore = (
      facility_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/passer/facility/${facility_id}/passerby-hourly/${date}/${startTime}/${endTime}/`;
    };

    this.getPasserCountCountHourlyByCamera = (
      device_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/passer/device/${device_id}/passerby-hourly/${date}/${startTime}/${endTime}/`;
    };

    //                                          get hourly dwell time hourly  count endpoinst
    this.getDwellTimeCountCountHourlyByAccount = (account_id, date) => {
      return `${BACKEND_URL}/dwell-time/hourly/userprofile/${account_id}/${date}/`;
    };

    this.getDwellTimeCounttHourlyByStore = (facility_id, date) => {
      return `${BACKEND_URL}/dwell-time/hourly/facility/${facility_id}/${date}/`;
    };

    this.getDwellTimeCountCountHourlyByCamera = (device_id, date) => {
      return `${BACKEND_URL}/dwell-time/hourly/device/${device_id}/${date}/`;
    };
    //                             get male female hourly

    this.getMaleFemaleCountCountHourlyByAccount = (
      account_id,
      date,
      startTime,
      endTime,
      cam_type,
      query_type
    ) => {
      return `${BACKEND_URL}/demographic/hourly/userprofile/${account_id}/${cam_type}/${query_type}/${date}/${startTime}/${endTime}/`;
    };

    this.getMaleFemaleCounttHourlyByStore = (
      facility_id,
      date,
      startTime,
      endTime,
      cam_type,
      query_type
    ) => {
      return `${BACKEND_URL}/demographic/hourly/facility/${facility_id}/${cam_type}/${query_type}/${date}/${startTime}/${endTime}/`;
    };

    this.getMaleFemaleCountHourlyByCamera = (
      device_id,
      date,
      startTime,
      endTime,
      cam_type,
      query_type
    ) => {
      return `${BACKEND_URL}/demographic/hourly/device/${device_id}/${cam_type}/${query_type}/${date}/${startTime}/${endTime}/`;
    };

    //                    totle male female count horuly

    this.getTotalMaleFemaleCountHourlyByAccount = (
      account_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/demographic/mvf/hourly/userprofile/${account_id}/${date}/${startTime}/${endTime}/`;
    };

    this.getTotalMaleFemaleCounttHourlyByStore = (
      facility_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/demographic/mvf/hourly/facility/${facility_id}/${date}/${startTime}/${endTime}/`;
    };

    this.getTotalMaleFemaleCountHourlyByCamera = (
      device_id,
      date,
      startTime,
      endTime
    ) => {
      return `${BACKEND_URL}/demographic/mvf/hourly/device/${device_id}/${date}/${startTime}/${endTime}/`;
    };

    // demographics weekly
    this.getTotalMaleFemaleCountWeeklyByAccount = (account_id, date) => {
      return `${BACKEND_URL}/demographic/weekly/userprofile/${account_id}/${date}/`;
    };

    this.getTotalMaleFemaleCounttWeeklyByStore = (facility_id, date) => {
      return `${BACKEND_URL}/demographic/weekly/facility/${facility_id}/${date}/`;
    };

    this.getTotalMaleFemaleCountWeeklyByCamera = (device_id, date) => {
      return `${BACKEND_URL}/demographic/weekly/device/${device_id}/${date}/`;
    };

    //                  get people count weekly endoints

    this.getPeopleCountWeeklyByAccount = (account_id, date) => {
      return `${BACKEND_URL}/peoplecount/userprofile/${account_id}/people-count-weekly/${date}/`;
    };

    this.getPeopleCountWeeklyByStore = (facility_id, date) => {
      return `${BACKEND_URL}/peoplecount/facility/${facility_id}/people-count-weekly/${date}/`;
    };

    this.getPeopleCountWeeklyByCamera = (device_id, date) => {
      return `${BACKEND_URL}/peoplecount/device/${device_id}/people-count-weekly/${date}/`;
    };
    //                  get three week people count data

    this.getThreeWeekPeopleCountByAccount = (account_id, date) => {
      return `${BACKEND_URL}/peoplecount/userprofile/${account_id}/three-week-people-count/${date}/`;
    };

    this.getThreeWeekPeopleCountByStore = (facility_id, date) => {
      return `${BACKEND_URL}/peoplecount/facility/${facility_id}/three-week-people-count/${date}/`;
    };

    this.getThreeWeekPeopleCountByCamera = (device_id, date) => {
      return `${BACKEND_URL}/peoplecount/device/${device_id}/three-week-people-count/${date}/`;
    };
    //                  get people count weekday people count

    this.getWeekDayPeopleCountByAccount = (account_id, date) => {
      return `${BACKEND_URL}/peoplecount/userprofile/${account_id}/weekday-people-count/${date}/`;
    };

    this.getWeekDayPeopleCountByStore = (facility_id, date) => {
      return `${BACKEND_URL}/peoplecount/facility/${facility_id}/weekday-people-count/${date}/`;
    };

    this.ggetWeekDayPeopleCountByCamera = (device_id, date) => {
      return `${BACKEND_URL}/peoplecount/device/${device_id}/weekday-people-count/${date}/`;
    };

    //                  get Passer count weekly endoints

    this.getPasserCountWeeklyByAccount = (account_id, date) => {
      return `${BACKEND_URL}/passer/userprofile/${account_id}/passerby-weekly/${date}/`;
    };

    this.getPasserCountWeeklyByStore = (facility_id, date) => {
      return `${BACKEND_URL}/passer/facility/${facility_id}/passerby-weekly/${date}/`;
    };

    this.getPasserCountWeeklyByCamera = (device_id, date) => {
      return `${BACKEND_URL}/passer/device/${device_id}/passerby-weekly/${date}/`;
    };
    //                  get three week Passer count data

    this.getThreeWeekPasserCountByAccount = (account_id, date) => {
      return `${BACKEND_URL}/passer/userprofile/${account_id}/three-week-passerby/${date}/`;
    };

    this.getThreeWeekPasserCountByStore = (facility_id, date) => {
      return `${BACKEND_URL}/passer/facility/${facility_id}/three-week-passerby/${date}/`;
    };

    this.getThreeWeekPasserCountByCamera = (device_id, date) => {
      return `${BACKEND_URL}/passer/device/${device_id}/three-week-passerby/${date}/`;
    };
    //                  get Passer weekday people count

    this.getWeekDayPasserCountByAccount = (account_id, date) => {
      return `${BACKEND_URL}/passer/userprofile/${account_id}/weekday-passerby/${date}/`;
    };

    this.getWeekDayPasserCountByStore = (facility_id, date) => {
      return `${BACKEND_URL}/passer/facility/${facility_id}/weekday-passerby/${date}/`;
    };

    this.getWeekDayPasserCountByCamera = (device_id, date) => {
      return `${BACKEND_URL}/passer/device/${device_id}/weekday-passerby/${date}/`;
    };
    //                  get Dwell TIme Weekly Data

    this.getDwellTimeWeeklyByAccount = (account_id, date) => {
      return `${BACKEND_URL}/dwell-time/weekly/userprofile/${account_id}/${date}/`;
    };

    this.getDwellTimeWeeklyByStore = (facility_id, date) => {
      return `${BACKEND_URL}/dwell-time/weekly/facility/${facility_id}/${date}/`;
    };

    this.getDwellTimeWeeklyByCamera = (device_id, date) => {
      return `${BACKEND_URL}/dwell-time/weekly/device/${device_id}/${date}/`;
    };

    // get all heatmap data endpoints

    this.getHeatMapByStore = (store_id, date) => {
      return `${BACKEND_URL}/heatmap/${store_id}/${date}/`;
    };

    // get ip cameras endpoints

    this.getIpCamerasByNvr = (nvr_id) => {
      return `${BACKEND_URL}/liveview-camera/${nvr_id}/`;
    };

    // get live view data

    this.getLiveViewData = (account_id, cam_id) => {
      return `${BACKEND_URL}/getlivestream/${account_id}/${cam_id}/`;
    };
    this.getLiveStreamWithControls = (account_id, cam_id, quality) => {
      return `${BACKEND_URL}/get/live/stream/rtc/${account_id}/${cam_id}/${quality}`;
    };
    this.stopLiveStream = (data) => {
      return `https://ffmpeg.agrexai.com:${data.stream_server_port}/stop?task_id=${data.task_id}&process_id=${data.process_id}&stream_id=${data.stream_id}`;
    };
    // get device Status
    this.getDeviceStatusData = (account_id) => {
      return `${BACKEND_URL}/device/${account_id}/status/`;
    };

    // male female  count api date range count people count
    this.getMaleFemaleDateRangePcCountByAccount = (
      account_id,
      startDate,
      endDate,
      cam_type,
      query_type
    ) => {
      return `${BACKEND_URL}/demographic/daterange/userprofile/${account_id}/${cam_type}/${query_type}/${startDate}/${endDate}/`;
    };
    this.getMaleFemaleDateRangePcCountByStore = (
      account_id,
      startDate,
      endDate,
      cam_type,
      query_type
    ) => {
      return `${BACKEND_URL}/demographic/daterange/facility/${account_id}/${cam_type}/${query_type}/${startDate}/${endDate}/`;
    };
    this.getMaleFemaleDateRangePcCountByCamera = (
      account_id,
      startDate,
      endDate,
      cam_type,
      query_type
    ) => {
      return `${BACKEND_URL}/demographic/daterange/device/${account_id}/${cam_type}/${query_type}/${startDate}/${endDate}/`;
    };

    // get all people count by dat range
    this.getAllPeopleCountDateRangeByAccount = (
      account_id,
      startDate,
      endDate
    ) => {
      return `${BACKEND_URL}/peoplecount/userprofile/${account_id}/people-count-daterange/${startDate}/${endDate}/`;
    };
    this.getAllPeopleCountDateRangeByStore = (
      account_id,
      startDate,
      endDate
    ) => {
      return `${BACKEND_URL}/peoplecount/facility/${account_id}/people-count-daterange/${startDate}/${endDate}/`;
    };
    this.getAllPeopleCountDateRangeByCamera = (
      account_id,
      startDate,
      endDate
    ) => {
      return `${BACKEND_URL}/peoplecount/device/${account_id}/people-count-daterange/${startDate}/${endDate}/`;
    };

    this.getAllPasserCountDateRangeByAccount = (
      account_id,
      startDate,
      endDate
    ) => {
      return `${BACKEND_URL}/passer/userprofile/${account_id}/passerby-daterange/${startDate}/${endDate}/`;
    };
    this.getAllPasserCountDateRangeByStore = (
      account_id,
      startDate,
      endDate
    ) => {
      return `${BACKEND_URL}/passer/facility/${account_id}/passerby-daterange/${startDate}/${endDate}/`;
    };
    this.getAllPasserCountDateRangeByCamera = (
      account_id,
      startDate,
      endDate
    ) => {
      return `${BACKEND_URL}/passer/device/${account_id}/passerby-daterange/${startDate}/${endDate}/`;
    };

    this.getDwellTimeRangeByAccount = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/dwell-time/daterange/userprofile/${account_id}/${startDate}/${endDate}/`;
    };
    this.getDwellTimeRangeByStore = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/dwell-time/daterange/facility/${account_id}/${startDate}/${endDate}/`;
    };
    this.getDwellTimeRangeByCamera = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/dwell-time/daterange/device/${account_id}/${startDate}/${endDate}/`;
    };
    // get male female by date range
    this.getMaleFemaleDateRangeByAccount = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/demographic/mvf/daterange/userprofile/${account_id}/${startDate}/${endDate}/`;
    };
    this.getMaleFemaleDateRangeByStore = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/demographic/mvf/daterange/facility/${account_id}/${startDate}/${endDate}/`;
    };
    this.getMaleFemaleDateRangeByCamera = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/demographic/mvf/daterange/device/${account_id}/${startDate}/${endDate}/`;
    };

    // get Emotion data by date range
    this.getEmotionDateRangeByAccount = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/demographic/mvf/daterange/userprofile/${account_id}/${startDate}/${endDate}/`;
    };
    this.getEmotionDateRangeByStore = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/demographic/mvf/daterange/facility/${account_id}/${startDate}/${endDate}/`;
    };
    this.getEmotionDateRangeByCamera = (account_id, startDate, endDate) => {
      return `${BACKEND_URL}/demographic/mvf/daterange/device/${account_id}/${startDate}/${endDate}/`;
    };

    // send report by mail
    this.reportDownloadByMail = (account_id, date, type) => {
      return `${BACKEND_URL}/download-report/${type}/userprofile/${account_id}/${date}/`;
    };
    this.reportDownloadByMailByCamera = (account_id, date, type, cameraId) => {
      return `${BACKEND_URL}/download-report/${type}/userprofile/${account_id}/${cameraId}/${date}/`;
    };
    this.reportDownloadByMailDateRange = (
      account_id,
      startDate,
      endDate,
      type
    ) => {
      return `${BACKEND_URL}/download-report/${type}/userprofile/${account_id}/${startDate},${endDate}/`;
    };
    this.reportDownloadByMailDateRangeByCamera = (
      account_id,
      startDate,
      endDate,
      type,
      camera_id
    ) => {
      return `${BACKEND_URL}/download-report/${type}/userprofile/${account_id}/${camera_id}/${startDate},${endDate}/`;
    };
    this.getGroupCountHourlyByAccount = (account_id, date) => {
      return `${BACKEND_URL}/group_people/hourly/userprofile/${account_id}/${date}/`;
    };

    this.getGroupCountHourlyByStore = (facility_id, date) => {
      return `${BACKEND_URL}/group_people/hourly/facility/${facility_id}/${date}/`;
    };

    this.getGroupCountHourlyByCamera = (device_id, date) => {
      return `${BACKEND_URL}/group_people/hourly/device/${device_id}/${date}/`;
    };
    this.getAllFacilityCamera = (profile_id) => {
      return `${BACKEND_URL}/liveview/v2/get-camera-list/${profile_id}/`;
    };
    this.getAllFacilityCameraPlayBack = (profile_id) => {
      return `${BACKEND_URL}/playback/v2/get-camera-list/${profile_id}/`;
    };
    this.getMonthlyAvailableRecording = (cam_id, year, month) => {
      return `${BACKEND_URL}/playback/dates/${cam_id}/${year}/${month}/`;
    };
    this.getPlaybackStream = (cam_id, date, start_time, end_time) => {
      let profile = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/get/playback/stream/rtc/${profile}/${cam_id}/main_stream?date=${date}&start_time=${start_time}&end_time=${end_time}`;
    };
    this.getPlaybackStreamInfo = (cam_id, date, start_time, end_time) => {
      let profile = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/getplayback/chunk/details/${cam_id}/${date}/${start_time}/${end_time}/${profile}`;
    };
    this.transferAlert = () => {
      return `${BACKEND_URL}/alerts/transfer/v2/`;
    };
    this.transferAlertIntoEvent = () => {
      return `${BACKEND_URL}/transfer/alert/event`;
    };

    this.deleteAlerts = (alert_id) => {
      return `${BACKEND_URL}/alerts/delete/${alert_id}/`;
    };
    this.transferEvent = () => {
      return `${BACKEND_URL}/events/transfer/`;
    };
    this.deleteEvents = (event_id) => {
      return `${BACKEND_URL}/events/delete/${event_id}/`;
    };
    this.getAllAccount = (user_profile) => {
      return `${BACKEND_URL}/accounts/${user_profile}/`;
    };
    this.getAllFacilityByAccountOP = (account_id) => {
      return `${BACKEND_URL}/account/facility/${account_id}/`;
    };
    this.getHeatmapDataUsingNvrId = (nvr_id) => {
      return `${BACKEND_URL}/nvr-status/heatmap?nvr_id=${nvr_id}`;
    };
    this.getUptimeHeatmapDataUsingNvrId = (nvr_id) => {
      return `${BACKEND_URL}/nvr-status/heatmap/uptime/${nvr_id}/`;
    };
    this.getAllFacilities = () => {
      return `${BACKEND_URL}/operations/facility/`;
    };
    this.createNVR = () => {
      return `${BACKEND_URL}/operations/nvr/`;
    };
    this.createNVRWithLimit = () => {
      return `${BACKEND_URL}/create/limit/nvr/`;
    };
    this.createCameraFromNvrAutomated = (id) => {
      return `${BACKEND_URL}/nvr/generate/camera/auto/${id}`;
    };
    this.editCameraFromNvrAutomated = (id) => {
      return `${BACKEND_URL}/nvr/generate/camera/auto/${id}?enable_live_view=false`;
    };
    this.getNvrById = (id) => {
      return `${BACKEND_URL}/operations/nvr/${id}/`;
    };
    this.checkIPAddress = (ip) => {
      return `${BACKEND_URL}/operations/check_ping/${ip}`;
    };
    this.useCaseList = (acc_id) => {
      return `${BACKEND_URL}/operations/usecasecamera/?account_id=${acc_id}`;
    };
    this.getAlertEventViaUsecase = (data) => {
      return `${BACKEND_URL}/operations/alert_event_from_usecase_day/${data.selectedUseCase}/${data.date}/${data.start_time}/${data.end_time}/${data.type}/${data.page_size}/${data.page_no}`;
    };
    //---------------------Nicobar dweel time ------------------
    this.getDwellTimeHourlyByAccountForNicobar = (account_id, date) => {
      return `${BACKEND_URL}/dwell-time/calc_dwell_time/userprofile/${account_id}/${date}`;
    };

    this.getDwellTimetHourlyByStoreForNicobar = (facility_id, date) => {
      return `${BACKEND_URL}/dwell-time/calc_dwell_time/facility/${facility_id}/${date}`;
    };

    this.getDwellTimeHourlyByCameraNicobar = (device_id, date) => {
      return `${BACKEND_URL}/dwell-time/calc_dwell_time/device/${device_id}/${date}`;
    };
    this.getLiveViewStreamHLS = (stream_id, is_vpn) => {
      try {
        if (is_vpn) {
          return `https://ffmpeg2.agrexai.com:443/stream/${stream_id}/channel/0/hls/live/index.m3u8`;
        } else {
          return `https://ffmpeg.agrexai.com:84/stream/${stream_id}/channel/0/hls/live/index.m3u8`;
        }
      } catch (error) {
        console.error("Error retrieving profile from localStorage:", error);
        return `https://ffmpeg.agrexai.com:84/stream/${stream_id}/channel/0/hls/live/index.m3u8`;
      }
    };

    this.deleteLiveViewStreamHLS = (data) => {
      try {
        const profile = JSON.parse(localStorage.getItem("profile"));
        if (data.vpn_tunnel) {
          return `https://ffmpeg2.agrexai.com:443/stream/${data.stream_id}/delete`;
        } else {
          return `https://ffmpeg.agrexai.com:84/stream/${data.stream_id}/delete`;
        }
      } catch (error) {
        console.error("Error retrieving profile from localStorage:", error);
        return `https://ffmpeg.agrexai.com:84/stream/${data.stream_id}/delete`;
      }
    };
    this.downloadCultFitReport = (user_profile_id, date) => {
      return `${BACKEND_URL}/analytics/foot_fall_excel_downloader/${user_profile_id}/${date}/${date}`;
    };
    this.getLastOnlineStatusOfBothApi = (nvrId) => {
      return `${BACKEND_URL}/nvr-status/iptimes/${nvrId}`;
    };
    this.getPermissionsByUserprofile = (id) => {
      return `${BACKEND_URL}/controlled_access/userprofile_permissions/${id}`;
    };
    this.CreateOrGetUserViaSSO = (access_token, used_for) => {
      return `${BACKEND_URL}/register/login/sso?access_token=${access_token}&used_for=${used_for}`;
    };
    this.runDiagonisticEndpoint = (nvr_id) => {
      return `${BACKEND_URL}/nvr/run/diagonistic/${nvr_id}`;
    };
    this.downloadSmcReport = (start_date, end_date) => {
      return `${BACKEND_URL}/anpr/export/?start_date=${start_date}&end_date=${end_date}`;
    };
    //this api is used to get the camera online , offline statistics based on user profile , nvr and facility
    this.getCameraStatsByUserProfile = (
      user_profile_id,
      facility_id,
      nvr_id
    ) => {
      let queryParams = new URLSearchParams({ user_profile_id });

      if (nvr_id) queryParams.set("nvr_id", nvr_id);
      else if (facility_id) queryParams.set("facility_id", facility_id);

      return `${BACKEND_URL}/nvr/camera_stats/?${queryParams}`;
    };
    this.addGetEmailCustomSetting = () => {
      return `${BACKEND_URL}/add/custom/emails/`;
    };
    this.updateEmailCustomSetting = (id) => {
      return `${BACKEND_URL}/update/custom/emails/${id}/`;
    };

    this.getEmailSettingForEmailCustomization = (user_profile_id) => {
      return `${BACKEND_URL}/get/custom/emails/detail/${user_profile_id}/`;
    };
    this.deleteEmailSettingCustomize = (id) => {
      return `${BACKEND_URL}/delete/custom/emails/${id}/`;
    };
    this.getAvailableRecordingDetails = (
      cam_id,
      date,
      start_time,
      end_time
    ) => {
      let profile = JSON.parse(localStorage.getItem("profile")).id;
      return `${BACKEND_URL}/get/available/recording/chunks?camera_id=${cam_id}&date=${date}&start_time${start_time}&end_time=${end_time}`;
    };
  }
}
export default new ApiSpec();
