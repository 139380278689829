import { React, useState, memo } from "react";
import PropTypes from "prop-types";
import "../../assets/css/main.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EditAlertModal from "./AlertTable/EditAlertModal";
import TransferAlertModal from "./AlertTable/TransferAlertModal";
import SendIcon from "@mui/icons-material/Send";
import PlaybackModal from "./AlertTable/PlaybackModal";
import { ColorRing } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import AlertTableAction from "../../redux/Action/AlertTableActions";
import "./../../assets/css/AlertEventCard.css";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import AlertTableModal from "./AlertTableModal";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { rhmsAlerts } from "../../configs/dataConfig";
import { generateEnjayTicket } from "../../redux/Action/AlertDataActon";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import { factory } from "typescript";
import { restructureResponse } from "./AlertTable/utils";
import TransferEventModal from "../Events/EventTable/TransferEventModal";
import PlaybackModalEvent from "../Events/EventTable/PlaybackModalEvent";
import EditEventModal from "../Events/EventTable/EditEventModal";
import EventTableAction from "../../redux/Action/EventTableActions";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const AlertTableCard = ({ incrementcount, title, item, index }) => {
  const isDarkmode = useSelector((state) => state.commonData.appTheme);
  const playbackCamList = useSelector(
    (state) => state.vmsHelper.cameraIdsForPlayback
  );
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Alerts ?? []
  );

  const [show, setShow] = useState(false);
  const [modalData, setmodalData] = useState({});
  const [itemRemark, setitemRemark] = useState(item.remark);
  const [editModal, setEditModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [playbackModal, setPlaybackModal] = useState(false);
  const [sendingNotification, setSendingNotification] = useState(false);
  const newData = restructureResponse(item);
  const dispatch = useDispatch();
  var profile = JSON.parse(localStorage.getItem("profile"));
  const handleAlertModalChange = () => {
    setEditModal(true);
  };

  const handleTransferAlertModalChange = () => {
    setTransferModal(true);
  };

  const handleRemoveClick = (data, item) => {
    setitemRemark({ ...item, remark: "" });
    handleClick("remark", { ...item, remark: "" });
    setCardRemark("");
  };

  const handlePlaybackModal = () => {
    setPlaybackModal(true);
  };
  const handleClick = (data, item) => {
    incrementcount(data, item);
  };

  const handleClose = () => setShow(false);
  const handleShow = (item, data) => {
    setmodalData(data);
    setShow(true);
  };
  const [cardRemark, setCardRemark] = useState(item.remark);

  const changeItemRemark = (e) => {
    setCardRemark(e.target.value);
  };

  const converDateInformate = (date) => {
    if (date) {
      const dateString = date;
      const parts = dateString.split("T")[0].split("-");
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  const sendNotificationToFacility = (alertId) => {
    setSendingNotification(true);
    dispatch(
      item.notification_type == "alerts"
        ? AlertTableAction.sendNotificationToFacility(alertId)
        : EventTableAction.sendNotificationToFacility(alertId)
    )
      .then(() => setSendingNotification(false))
      .catch(() => setSendingNotification(false));
  };
  const is_playback =
    playbackCamList.includes(item.camera[1]) &&
    !rhmsAlerts.includes(item.alert_type);

  async function handleAlertAction(operation, id) {
    let patchData = {};
    if (operation == "dismiss") {
      patchData.is_false = true;
    }
    if (operation == "resolve") {
      patchData.is_resolved = true;
    }
    if (operation == "revert") {
      patchData.is_false = false;
      patchData.is_resolved = false;
    }
    if (operation == "add_remark") {
      patchData.remark = cardRemark;
      setitemRemark(cardRemark);
    }
    if (operation == "clear_remark") {
      patchData.remark = "";
      setitemRemark("");
    }
    await incrementcount(patchData, id);
    if (operation == "clear_remark") setCardRemark("");
  }
  async function generateTicket(id) {
    await generateEnjayTicket(id);
  }
  const isNVRAlertType = [
    "nvr_offline_alert",
    "hdd_not_recording",
    "hdd_health",
  ].includes(newData.alert_type);
  return (
    <div>
      <div>
        <div key={newData.id}>
          <br />
          <div
            className={
              "main-alert-event-card" + isDarkmode
                ? "card-shadow-alert-event-card-dark"
                : "card-shadow-alert-event-card-light"
            }
            style={{
              margin: "0px",
              borderRadius: "10px",
            }}
          >
            <div className="image-div-on-card">
              <div className="icons-above-table-card">
                {profile.services_selected.Alert.includes(
                  "alert_event_playback"
                ) && (
                  <div
                    className="table-card-icon"
                    onClick={handlePlaybackModal}
                  >
                    <OndemandVideoIcon sx={{ fontSize: "20px" }} />
                  </div>
                )}
                {profile.services_selected.Alert.includes("FacilityAlert") &&
                  access_permission.includes("send_notification_to_facility") &&
                  profile.account.config.Operation !== "True" && (
                    <button className="table-card-icon">
                      {!sendingNotification ? (
                        <LightTooltip title="Notify Facility">
                          <NotificationsActiveIcon
                            sx={{ fontSize: "20px", color: "#fff" }}
                            onClick={() =>
                              sendNotificationToFacility(newData.id)
                            }
                          />
                        </LightTooltip>
                      ) : (
                        <ColorRing
                          visible={true}
                          height="35"
                          width="35"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      )}
                    </button>
                  )}
                {profile.services_selected.EnjayIntegration.length !== 0 &&
                  profile.account.config.Operation !== "True" &&
                  access_permission.includes("enjay_ticket") && (
                    <div style={{ margin: "0 2px" }} title="Generate Ticket">
                      <button className="table-card-icon">
                        <BookmarkAddOutlinedIcon
                          sx={{ fontSize: "20px", color: "#fff" }}
                          onClick={() => generateTicket(newData.id)}
                        />
                      </button>
                    </div>
                  )}
                {profile.account.config.Operation === "True" && (
                  <div
                    className="table-card-icon"
                    onClick={handleAlertModalChange}
                  >
                    <EditIcon sx={{ fontSize: "20px" }} />
                  </div>
                )}

                {profile.account.config.Operation === "True" && (
                  <div
                    className="table-card-icon"
                    onClick={handleTransferAlertModalChange}
                  >
                    <SendIcon sx={{ fontSize: "20px" }} />
                  </div>
                )}
              </div>
              <img
                className="event-image-new"
                style={{ height: "160px", width: "100%" }}
                onClick={() => handleShow(true, newData)}
                src={
                  newData.type == "device_offline_alert"
                    ? "https://analytics-data-collection.s3.ap-south-1.amazonaws.com/alerts/device_offline_alert.png"
                    : newData.image
                }
                alt="Card image cap"
              />
            </div>
            <div className="card-details-on-card">
              <div className="alert-event-type-label mt-2">
                <div>
                  {newData && newData.type && (
                    <h4 style={{ fontSize: "14px" }}>
                      {newData.type === "rtsp_not_working_alert"
                        ? "RTSP Not Working"
                        : newData.type == "loading"
                        ? "Loading / Unloading"
                        : (newData.type.length > 24
                            ? newData.type.slice(0, 24) + ".."
                            : newData.type
                          )
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </h4>
                  )}
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0",
                      color: "var(--card-text-color)",
                    }}
                  >
                    <StoreMallDirectoryIcon
                      sx={{ marginRight: "5px", fontSize: "20px" }}
                    />
                    {(newData.facility[0].length > 14
                      ? newData.facility[0].slice(0, 14) + ".."
                      : newData.facility[0]
                    )
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </p>
                </div>
                <div className="alert-card-time-date">
                  {moment.utc(newData.received_at).format("h:mm A")}
                  <br />
                  {converDateInformate(newData.received_at)}
                </div>
              </div>

              {isNVRAlertType && newData.nvr && newData.nvr.name ? (
                <div className="alert-event-type-label mt-2">
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: "0",
                        color: "var(--card-text-color)",
                      }}
                    >
                      <VideogameAssetIcon
                        sx={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      {(newData.nvr.name.length > 20
                        ? newData.nvr.name.slice(0, 20) + "..."
                        : newData.nvr.name
                      )
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="alert-event-type-label mt-2">
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: "0",
                        color: "var(--card-text-color)",
                      }}
                    >
                      <CameraAltIcon
                        sx={{ marginRight: "5px", fontSize: "20px" }}
                      />
                      {(newData.camera[0].length > 20
                        ? newData.camera[0].slice(0, 20) + "..."
                        : newData.camera[0]
                      )
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </p>
                  </div>
                </div>
              )}
              <div>
                {profile.account.config.Operation === "True" && (
                  <div className="alert-even-card-div-loc-chan-message">
                    <p
                      style={{
                        fontSize: "14px",
                        color: "var(--card-text-color)",
                      }}
                    >
                      {newData.message
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <div className="col-12 alert-card-div">
                  <div
                    className="col-12 alerts"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className={
                        "d-flex " +
                        (newData.priority == "High"
                          ? "priority-div"
                          : "priority-div-event")
                      }
                    >
                      <OnlinePredictionIcon sx={{ marginRight: "4px" }} />{" "}
                      {newData.priority}
                    </div>
                    <div className="d-flex ">
                      {access_permission.includes("can_resolve") && (
                        <div style={{ margin: "0 4px" }}>
                          {(title === "alert" || title === "event") && (
                            // <LightTooltip title="Resolve">
                            <button
                              onClick={() =>
                                handleAlertAction("resolve", newData.id)
                              }
                              type="submit"
                              className="alert-card-reso-btn alert-card-resolve-dismiss"
                            >
                              Resolve
                            </button>
                            // </LightTooltip>
                          )}
                        </div>
                      )}

                      <div>
                        {title === "alert" &&
                          access_permission.includes("can_dismiss") && (
                            // <LightTooltip title="Dismiss">
                            <button
                              onClick={() =>
                                handleAlertAction("dismiss", newData.id)
                              }
                              type="submit"
                              className="alert-card-diss-btn alert-card-resolve-dismiss"
                            >
                              Dismiss
                            </button>
                            // </LightTooltip>
                          )}
                      </div>

                      <div>
                        {title != "alert" &&
                          access_permission.includes("show_alert_tab") && (
                            // <LightTooltip title="Revert">
                            <button
                              onClick={() =>
                                handleAlertAction("revert", newData.id)
                              }
                              type="submit"
                              className="alert-card-revert-btn alert-card-resolve-dismiss"
                            >
                              Revert
                            </button>
                            // </LightTooltip>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {access_permission.includes("can_remarks") && (
                  <div
                    className="pwd col-12  input-remark-alert-event-div"
                    style={{ marginTop: "15px" }}
                  >
                    <input
                      className="remark-input col-10 col-xl-9 input-remark-alert-event"
                      value={cardRemark}
                      placeholder={"Remark"}
                      onChange={changeItemRemark}
                    />
                    <span className="pp-viewer col">
                      {itemRemark ? (
                        <CancelIcon
                          onClick={() =>
                            handleAlertAction("clear_remark", newData.id)
                          }
                          style={{
                            color: " var(--card-text-color)",
                            fontSize: "30px",
                            marginTop: "2px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <AddCircleIcon
                          onClick={() =>
                            handleAlertAction("add_remark", newData.id)
                          }
                          style={{
                            color: " var(--card-text-color)",
                            fontSize: "30px",
                            marginTop: "2px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <AlertTableModal
          show={show}
          handleClose={handleClose}
          item={newData}
          title={title}
          incrementcount={incrementcount}
          index={index}
          event_image2={item.event_image2}
        />
      )}
      {editModal &&
        (item.notification_type == "alerts" ? (
          <EditAlertModal
            item={item}
            show={editModal}
            incrementcount={incrementcount}
            handleClose={() => setEditModal(false)}
          />
        ) : (
          <EditEventModal
            item={item}
            show={editModal}
            incrementcount={incrementcount}
            handleClose={() => setEditModal(false)}
          />
        ))}
      {transferModal &&
        (item.notification_type == "alerts" ? (
          <TransferAlertModal
            item={item}
            show={transferModal}
            incrementcount={incrementcount}
            handleClose={() => setTransferModal(false)}
          />
        ) : (
          <TransferEventModal
            item={item}
            show={transferModal}
            incrementcount={incrementcount}
            handleClose={() => setTransferModal(false)}
          />
        ))}
      {playbackModal &&
        (item.notification_type === "alerts" ? (
          <PlaybackModal
            item={item}
            show={playbackModal}
            handleClose={() => setPlaybackModal(false)}
          />
        ) : (
          <PlaybackModalEvent
            item={item}
            show={playbackModal}
            handleClose={() => setPlaybackModal(false)}
          />
        ))}
    </div>
  );
};
AlertTableCard.propTypes = {
  incrementcount: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
export default AlertTableCard;
