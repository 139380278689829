import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { getNVRStatusByAccount } from "../../../redux/Action/RhmsAction";
import { useDispatch } from "react-redux";
import { updateNVRById } from "../../../redux/Action/RhmsAction";
import { createNVR } from "../../../redux/Action/RhmsAction";
import SelectFieldComp from "../../../components/common/FormField/SelectFieldComp";
import IpValidationModal from "../ConfigurableRhms/IpValidationModal";
import { ThreeDots } from "react-loader-spinner";
import RingLoader from "../../../components/common/Loader/RingLoader";
import AccessControlActions from "../../../redux/Action/AccessControlAction";
const channelOption = [4, 8, 16, 32, 64];
const nvrOptions = [
  {
    label: "Hikvision",
    value: "Hikvision",
  },
  {
    label: "CP Plus/Dahua",
    value: "CP Plus",
  },
  {
    label: "Uniview",
    value: "Uniview",
  },
  {
    label: "Securus",
    value: "Securus",
  },
  {
    label: "Indinatus",
    value: "indinatus",
  },
  {
    label: "Other",
    value: "other",
  },
];
import AutocompleteCompUncontrollable from "../../../components/common/FormField/AutoCompleteUncontrollable";
import ApiSpec from "../../../redux/features/EndPoints";
import axios from "axios";
import AutocompleteComp from "../../../components/common/FormField/Autocomplete";
import Add from "@mui/icons-material/Add";
import AddNewFacility from "./AddNewFacility";
import ActiveChannelComponent from "./ActiveChannelComponent";
import { notifiToasterWarn } from "../../../components/common/UseToast";
function AddNvrDvr(propTypes) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [channelCapacity, setChannelCapacity] = useState(4);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [brand, setBrand] = useState(null);
  const [ipadd, setIpadd] = useState("");
  const [cameraConnected, setCameraConnected] = useState("");
  const [httpsPort, setHttpsPort] = useState("");
  const [channelUnused, setChannelUnused] = useState("");
  const [httpPort, setHttpPort] = useState("");
  const [rtspPort, setRtspPort] = useState("");
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [nvrData, setNvrData] = useState(null);
  const [checkingIP, setCheckingIP] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    name: "",
    facility: "",
    brand: "",
  });
  const [uncheckedChannels, setUncheckedChannels] = useState([]);
  const [disableAddAnother, setDisableAddAnother] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedPort, setSelectedPort] = useState({
    label: "HTTP",
  });
  const [facilityModalOpen, setFacilityModalOpen] = useState(false);
  const [state, setState] = React.useState({
    cloudEnable: true,
  });

  function handleChannelChange(val) {
    setChannelCapacity(val);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) return;
    // Reset the specific error when the input changes
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "name") setName(value);
    if (name === "cameraConnected") setCameraConnected(value);
    if (name === "channelUnused") setChannelUnused(value);
    if (name === "brand") setBrand(value);
    if (name === "httpPort") setHttpPort(value);
    if (name === "httpsPort") setHttpsPort(value);
    if (name === "rtspPort") setRtspPort(value);
    if (name === "ipadd") setIpadd(value);
  };
  function getChannelUsedData(channelUnused, capacity) {
    // Initialize an empty array to collect used channels
    const channel = [];

    // Loop through numbers from 1 to capacity (inclusive)
    for (let i = 1; i <= Number(capacity); i++) {
      // Check if the channel is not unused. convert i to string then check if channelUnused includes this channel
      if (channelUnused.includes(`${i}01`)) {
        // If the channel is used, add it to the array
        channel.push(i);
      }
    }

    // Return the array of used channels
    return channel;
  }
  function filterfacilityById(inputArray, id) {
    if (inputArray && id && inputArray.length > 0) {
      const filteredArray = inputArray.filter((obj) => obj.value === id);

      return filteredArray[0];
    }
  }
  function filterNvrBrand(inputArray, brand) {
    if (inputArray && brand && brand.length > 0 && inputArray.length > 0) {
      const filteredArray = inputArray.filter(
        (obj) => obj.value.toLowerCase() == brand.toLowerCase()
      );

      return filteredArray[0];
    }
  }
  useEffect(() => {
    if (propTypes.id && propTypes.id != -1) {
      if (nvrData) {
        setUsername(nvrData.username);
        setBrand(
          nvrData.brand ? filterNvrBrand(nvrOptions, nvrData.brand) : null
        );
        setName(nvrData.name);
        setHttpPort(nvrData.http_port_number ? nvrData.http_port_number : "");
        setHttpsPort(
          nvrData.https_port_number ? nvrData.https_port_number : ""
        );
        setChannelCapacity(Number(nvrData.channel_capacity));
        setRtspPort(nvrData.rtsp_port);
        setPassword(nvrData.password);
        setIpadd(nvrData.ip_address);
        setCameraConnected(nvrData.cameras_connected);
        setUncheckedChannels(
          getChannelUsedData(nvrData.channels_unused, nvrData.channel_capacity)
        );
        setState({ ...state, cloudEnable: nvrData.cloud_monitoring_enabled });
        if (facilitiesList && nvrData) {
          setSelectedFacility(
            filterfacilityById(facilitiesList, nvrData.installed_at)
          );
        }
      }
    }
  }, [nvrData, facilitiesList]);
  const fetchDataById = async () => {
    try {
      var token = localStorage.getItem("token");
      const response = await axios.get(ApiSpec.getNvrById(propTypes.id), {
        headers: { Authorization: `Token ${token}` },
      });
      setNvrData(response.data);
    } catch (error) {
      console.error("Error fetching Nvr data:", error);
    }
  };
  const validateIpAddress = async (ipAdd) => {
    try {
      setCheckingIP(true);
      var token = localStorage.getItem("token");
      const response = await axios.get(ApiSpec.checkIPAddress(ipAdd), {
        headers: { Authorization: `Token ${token}` },
      });
      setCheckingIP(false);
      return true;
    } catch (error) {
      setCheckingIP(false);
      return false;
    }
  };
  async function checkIpAddress(ipAddress) {
    try {
      const isIpAddressValid = await validateIpAddress(ipAddress);
      if (isIpAddressValid) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  const validateForm = () => {
    const newErrors = {
      name: "",
      ipadd: "",
      username: "",
      password: "",
      facility: "",
      brand: "",
      cameraConnected: "",
      rtspPort: "",
      httpPort: "",
      httpsPort: "",
      channelUnused: "",
      channelCapacity: "",
    };

    if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long";
    }

    if (!/^[0-9.]+$/.test(ipadd)) {
      newErrors.ipadd = "Enter valid IP Address";
    }
    if (username.length < 3) {
      newErrors.username = "Username must be at least 3 characters long";
    } else if (username.includes(" ")) {
      newErrors.username = "Username must not contain any spaces";
    }

    if (password.length < 3) {
      newErrors.password = "Password must be at least 3 characters long";
    } else if (password.includes(" ")) {
      newErrors.password = "Password must not contain any spaces";
    }

    if (!brand) {
      newErrors.brand = "Brand Name is required";
    }

    if (!selectedFacility) {
      newErrors.facility = "Facility Name is required";
    }

    if (!channelCapacity) {
      newErrors.channelCapacity = "Channel Capacity is required";
    }

    if (!rtspPort) {
      newErrors.rtspPort = "Please enter a valid RTSP port";
    }

    const port = selectedPort.label === "HTTP" ? httpPort : httpsPort;
    if (!port || port === 0) {
      newErrors.port = `Please enter ${selectedPort.label} port`;
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      try {
        const profile_id = localStorage.getItem("profile_id");
        const userData = {
          name: name,
          brand: brand.value,
          ip_address: ipadd,
          channel_capacity: channelCapacity,
          cameras_connected: Number(channelCapacity) - uncheckedChannels.length,
          channels_unused: uncheckedChannels.map((item) => `${item}01`),
          username: username,
          password: password,
          rtsp_port: rtspPort,
          cloud_monitoring_enabled: true,
          installed_at: selectedFacility.value,
          user_profile_id: profile_id,
        };

        if (selectedPort.label === "HTTPS") {
          userData.https_port_number = httpsPort;
        } else {
          userData.http_port_number = httpPort;
        }

        setLoading(true);

        try {
          if (propTypes.isEditable) {
            dispatch(updateNVRById(userData, propTypes.id))
              .then(() => {
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          } else {
            dispatch(createNVR(userData))
              .then(() => {
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
            resetAllStates();
          }
          propTypes.onClose();
          dispatch(getNVRStatusByAccount(profile_id, 1));
        } catch (error) {
          console.error("An error occurred:", error);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const resetAllStates = () => {
    setUsername("");
    setPassword("");
    setName("");
    setChannelCapacity(4);
    setBrand(brand);
    setIpadd("");
    setCameraConnected("");
    setHttpsPort("");
    setChannelUnused("");
    setHttpPort("");
    setRtspPort("");
    setSelectedFacility(selectedFacility);
    setNvrData({});
  };

  function getAllFacilitiesList() {
    var token = localStorage.getItem("token");
    var profile_id = JSON.parse(localStorage.getItem("profile")).id;
    axios
      .get(ApiSpec.getStoresList(profile_id), {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        const facilitiesData = response.data.map((item) => ({
          label: item.facility_name,
          value: item.id,
        }));
        setFacilitiesList(facilitiesData);
      });
  }
  function handleFacilityChangeControlled(val) {
    if (val) {
      setSelectedFacility(val);
    }
  }
  function handleFacilityChange(val) {
    if (val) {
      setSelectedFacility(val);
    }
  }
  function handleSelectPort(e, val) {
    setSelectedPort(val);
  }
  function handleBrandControlled(e, val) {
    if (val) {
      setBrand(val);
    }
  }
  function handleBrand(val) {
    if (val) {
      setBrand(val);
    }
  }
  useEffect(() => {
    getAllFacilitiesList();
    if (propTypes.id && propTypes.id != -1) {
      fetchDataById();
    }
  }, [propTypes.id]);

  const handleUncheckedChannelsChange = (uncheckedChannels) => {
    setUncheckedChannels(uncheckedChannels);
  };
  if (!selectedFacility && propTypes.isEditable) {
    return (
      <div
        style={{ width: "300px", height: "30vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <RingLoader style={{ fontSize: "80px" }} />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="name"
            label="NVR/DVR Name"
            variant="outlined"
            fullWidth
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="ipadd"
            label="IP Address"
            variant="outlined"
            fullWidth
            type="text"
            name="ipadd"
            value={ipadd}
            onChange={handleChange}
            error={Boolean(errors.ipadd)}
            helperText={errors.ipadd}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="username"
            label="NVR/DVR Username"
            variant="outlined"
            fullWidth
            type="text"
            name="username"
            value={username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="password"
            label="NVR/DVR Password"
            variant="outlined"
            fullWidth
            autoComplete="off"
            name="password"
            type="text"
            value={password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            required
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3 row m-0 p-0">
          <div className="col-10">
            {propTypes.isEditable ? (
              <AutocompleteComp
                option={facilitiesList}
                onChange={handleFacilityChangeControlled}
                value={selectedFacility}
                label="Facility"
                showValue={false}
              />
            ) : (
              <AutocompleteCompUncontrollable
                option={
                  facilitiesList && facilitiesList.length > 0
                    ? facilitiesList
                    : []
                }
                onChange={handleFacilityChange}
                label={"Facility *"}
                error={errors.length > 0}
                helperText={errors.facility ?? ""}
              />
            )}
          </div>
          <div className="col-2 d-flex justify-content-center align-items-center">
            <IconButton size="small" onClick={() => setFacilityModalOpen(true)}>
              <Add />
            </IconButton>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-lg-6 mb-3">
          {propTypes.isEditable ? (
            <AutocompleteComp
              option={nvrOptions}
              onChange={handleBrandControlled}
              value={brand}
              label={"NVR Brand *"}
              showValue={false}
            />
          ) : (
            <AutocompleteCompUncontrollable
              option={nvrOptions}
              onChange={handleBrand}
              label={"NVR Brand *"}
              helperText={errors.brand ?? ""}
              error={errors.brand.length > 0}
            />
          )}
        </div>

        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <SelectFieldComp
            options={channelOption}
            value={isNaN(channelCapacity) ? "" : channelCapacity}
            onChange={handleChannelChange}
            label="Channel Capacity"
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="rtspPort"
            label="RTSP Port Number"
            variant="outlined"
            fullWidth
            name="rtspPort"
            type="number"
            value={rtspPort}
            onChange={handleChange}
            error={Boolean(errors.rtspPort)}
            helperText={errors.rtspPort}
            required
          />
        </div>

        <div className="form-group col-12 col-sm-6 col-lg-6 mb-3">
          <TextField
            id="httpPort"
            label="HTTP Port Number"
            variant="outlined"
            fullWidth
            name="httpPort"
            type="number"
            value={httpPort}
            onChange={handleChange}
            required={selectedPort.label === "HTTP"}
            error={Boolean(errors.rtspPort)}
            helperText={errors.httpPort ?? ""}
          />
        </div>
        <ActiveChannelComponent
          channelCapacity={channelCapacity}
          uncheckedChannels={uncheckedChannels}
          onUncheckedChannelsChange={handleUncheckedChannelsChange}
        />

        <div className="col-4 mt-3">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!disableAddAnother || loading}
          >
            {loading ? (
              <ThreeDots
                height="30"
                width="80"
                radius="7"
                color="var(--card-text-color)"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : propTypes.isEditable ? (
              "Update NVR"
            ) : (
              "Add NVR"
            )}
          </Button>
        </div>
      </form>
      <IpValidationModal open={checkingIP} />
      <AddNewFacility
        isOpen={facilityModalOpen}
        handleClose={() => setFacilityModalOpen(false)}
        getAllFacilitiesList={getAllFacilitiesList}
      />
    </div>
  );
}

export default AddNvrDvr;
