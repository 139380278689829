import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";
import { ColorRing } from "react-loader-spinner";
import { reportDownloadByMail } from "../../redux/Action/CommonAction";
import { totalNvrDataForLocalDownload } from "../../redux/Action/RhmsAction";
import { useState } from "react";
import mainCss from "../../assets/css/mainCss";

const DownloadAndEmailReport = () => {
  const [access_permission] = useState(
    JSON.parse(localStorage.getItem("agx_permission")).Remote_monitoring ?? []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);

  /**
   * Sets the loading state to true, fetches total Nvr (Network Video Recorder) data and downloads it as a report.
   * If there is an error while fetching the data, the loading state is set to false.
   */
  const handleLocalDownloadData = () => {
    setIsLoading(true);
    totalNvrDataForLocalDownload()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "NVR_Health_Report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  /**
   * Sends an email report based on given parameters, setting/loading states appropriately.
   */
  const handleSendEmailReport = () => {
    setIsLoadingg(true);
    const type = "remote-monitoring";
    reportDownloadByMail(Date.now(), type, "day-wise", Date.now())
      .then(() => {
        setIsLoadingg(false);
      })
      .catch(() => {
        setIsLoadingg(false);
      });
  };

  return (
    <div>
      {access_permission.includes("nvr_email_download_report") && (
        <div className="col mt-4">
          <div className="d-flex justify-content-end">
            <div
              className="btn btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={handleLocalDownloadData}
            >
              {!isLoading ? (
                <DownloadIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>

            <div
              className="btn btn-sm m-1 shadow-none"
              style={mainCss.downloadButton}
              onClick={handleSendEmailReport}
            >
              {!isLoadingg ? (
                <EmailIcon sx={mainCss.downloadIcon} />
              ) : (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#ffff", "#ffff", "#ffff", "#ffff", "#ffff"]}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadAndEmailReport;
